<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'
import { z } from 'zod'

type CheckoutState =
  | {
      type: 'initial'
    }
  | {
      type: 'submitting'
    }
  | {
      type: 'error'
      error: string
    }

const model = defineModel<boolean>()
const { cart, checkOut, getItemQuantity, removeFromCart, updateQuantity } =
  useShoppingCart()
const { getProductsById } = useProducts()
const toast = useToast()

const checkoutSchema = z.object({
  shippingInfo: z.string().trim().min(1, 'Shipping details are required')
})

type CheckoutSchema = z.infer<typeof checkoutSchema>

const checkoutFormState = reactive<CheckoutSchema>({
  shippingInfo: ''
})

const checkoutState = ref<CheckoutState>({ type: 'initial' })
const products = ref<Array<ProductWithImages & { quantity: number }>>([])

const cartTotal = computed(() =>
  products.value.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  )
)

const isShippingInfoComplete = computed(() => {
  return Object.values(checkoutFormState).every(value => value.trim() !== '')
})

const scrollToBottom = () => {
  const scrollableContainer = document.querySelector(
    '#checkout .overflow-y-auto.flex.flex-col'
  )
  if (scrollableContainer) {
    scrollableContainer.scrollTop = scrollableContainer.scrollHeight
  }
}

const refreshCart = async () => {
  const loadedProducts = await getProductsById(
    Object.keys(cart.value).map(id => parseInt(id))
  )

  products.value = loadedProducts.map(product => ({
    ...product,
    quantity: getItemQuantity(product.id)!
  }))
}

const handleSubmitOrder = async (event: FormSubmitEvent<CheckoutSchema>) => {
  if (
    products.value.length === 0 ||
    checkoutState.value.type === 'submitting' ||
    !isShippingInfoComplete.value
  ) {
    return
  }

  checkoutState.value = { type: 'submitting' }

  try {
    await checkOut(event.data)

    toast.add({
      color: 'green',
      title: 'Your order has been placed!',
      icon: 'i-heroicons-check'
    })

    model.value = false

    checkoutState.value = { type: 'initial' }

    Object.keys(checkoutFormState).forEach(key => {
      checkoutFormState[key as keyof CheckoutSchema] = ''
    })
  } catch (error) {
    checkoutState.value = {
      type: 'error',
      error: 'Could not complete checkout'
    }

    toast.add({
      color: 'red',
      title: 'Could Not Complete Checkout',
      icon: 'i-heroicons-exclamation-circle',
      description: 'Please try again.'
    })
  }
}

const handleQuantityChange = (productId: number, newQuantity: number) => {
  const product = products.value.find(p => p.id === productId)
  if (product) {
    product.quantity = newQuantity
    updateQuantity(productId, newQuantity)
  }
}

watch(() => cart.value, refreshCart, { immediate: true, deep: true })
</script>

<template>
  <FormModalForm
    id="checkout"
    v-model="model"
    modal
    :loading="checkoutState.type === 'submitting'"
    :schema="checkoutSchema"
    :state="checkoutFormState"
    @submit="handleSubmitOrder"
  >
    <template #header>
      <h3 class="text-2xl font-bold">Checkout</h3>
    </template>

    <UAlert
      v-if="products.length === 0"
      icon="i-heroicons-information-circle"
      title="Your cart is empty"
    />
    <template v-else>
      <div v-for="product in products" :key="`product-${product.id}`">
        <div class="my-8 flex gap-4">
          <aside>
            <NuxtImg
              v-if="product.product_images.length > 0"
              provider="supabase"
              :src="product.product_images[0]!.image_name ?? undefined"
              :alt="product.name"
              class="max-w-32"
            />
          </aside>
          <div class="flex-grow">
            <h4>{{ product.name }}</h4>
            <div class="my-3 text-sm">{{ product.description }}</div>
            <UFormGroup label="Quantity" required>
              <UInput
                :model-value="product.quantity"
                type="number"
                v-bind="{ min: 1 }"
                @update:model-value="
                  newValue => handleQuantityChange(product.id, newValue)
                "
              />
              <template #help>
                <UButton
                  color="red"
                  :padded="false"
                  variant="link"
                  @click="removeFromCart(product.id)"
                >
                  Remove
                </UButton>
              </template>
            </UFormGroup>
          </div>
          <aside>
            <span class="text-2xl font-bold">
              {{ formatPrice(product.price * product.quantity) }}
            </span>
            <small v-if="product.quantity > 1">
              ({{ formatPrice(product.price) }} x {{ product.quantity }})
            </small>
          </aside>
        </div>
        <UDivider />
      </div>
      <div class="mt-4 text-end text-2xl font-bold">
        Total: {{ formatPrice(cartTotal) }}
      </div>
      <UDivider label="Shipping Information" />
      <UFormGroup
        label="Please enter detailed shipping information here"
        help="How many are going to which address? Any other shipping notes?"
        name="shipping"
        required
      >
        <UTextarea v-model="checkoutFormState.shippingInfo" />
      </UFormGroup>
    </template>

    <template v-if="products.length > 0" #footer>
      <UButton
        v-if="!isShippingInfoComplete"
        type="button"
        variant="outline"
        :disabled="products.length === 0"
        @click="scrollToBottom"
      >
        Fill in your shipping info to proceed
      </UButton>
      <UButton
        v-else
        type="submit"
        variant="outline"
        :disabled="products.length === 0"
        :loading="checkoutState.type === 'submitting'"
      >
        Submit Order
      </UButton>
    </template>
  </FormModalForm>
</template>
